import React, {useState, useRef, useEffect} from 'react';


const TextInput = ({name, label, type, reg, errors }) => {

    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef(null);
  

    const handleFocus= () => {

        setIsFocused(true)

    }
    const handleBlur = () => {
        
        !inputRef.current.value && setIsFocused(false);
        
    }

    useEffect (() => {
        
        //no idea why I can't only get this to fire on mount basically, have followed the useEffect instructions from the reactjs website, kept getting is not a function error even though console.log clearly showed it as a function?
        reg(inputRef.current)
       
    },[reg, inputRef])
 



    return (

        <div className={`input-container ${isFocused ? "focused" : ''}`}>
            <label htmlFor={name}>{label}</label>
            <input type={type}
                    id={name}
                    name={name}
                    className={name}
                    ref={inputRef}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                   
                    />
            <div className="error">{errors && errors.message}</div>

        </div>
    )
    
};

export default TextInput;