import React, { useState } from 'react'
import TextInput from '../components/forms/TextInput';
import TextareaInput from '../components/forms/TextareaInput';
import { useForm } from 'react-hook-form/dist/react-hook-form.ie11';

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHeading from '../components/pageheading'
import axios from 'axios';

const ContactUs = ({data}) => {
    
    const {register, handleSubmit, watch, errors} = useForm();
    const watchFields = watch();
    const [notifyMessage, setNotifyMessage] = useState('')

    const onSubmit = (data, e) => {


        const response =  axios.post(
            '/forms/contact-form.php',
            data ,
            { headers: { 'Content-Type': 'application/json' } }
        ).then(res => {
        
            if(res.data === true){
                //console.log('true')
                setNotifyMessage('Thank you we will be in touch shortly.')
                e.target.reset();

            }else {
               // console.log('false' + res.data)
                setNotifyMessage(res.data);
            }
           
        }) 
    
      

    }

    const FormMessage = ({message}) => {
        return (
            <>
            <div className="notify-overlay" onClick={() => setNotifyMessage(null)} onKeyDown={() => setNotifyMessage(null)} role="button" tabIndex="0">
                <div className="notification" onClick={() => setNotifyMessage(null)} onKeyDown={() => setNotifyMessage(null)}  role="button" tabIndex="-1">
                    <p className="success-message">
                        {message}
                    </p>
                </div>
            </div>
            
           
            </>
        );
    }

    
    return (
        <Layout pageClass="contact-us">
        <SEO title="Contact Us" description="Contact Us at Modus Developments in Auckland, New Zealand" />
        <PageHeading title="Contact Us" imageData={data.headerImage} />
        <main className="main">
            <div>
            <div className="typography inner">
                <address>
                    <span>86 Marine Parade</span>
                    <span>Mellons Bay</span>
                    <span>Auckland 2014</span>
                    <a href="mailto:info@modusdevelopments.nz" className="email">info@modusdevelopments.nz</a>
                    <a href="tel:+6495337445" className="phone">09 533 7445</a>
                    <a href="tel:+64274962129" className="mobile">0274 962 129</a>
                </address>

                <h2>Contact Form</h2>
                <p className="center">Modus Developments works with architects and clients throughout the greater Auckland area. <br />We’re always happy to meet over a coffee and discuss what you have in mind.</p>

                <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field" onSubmit={handleSubmit(onSubmit)}>
                    <input type="hidden" name="bot-field" />

                    <TextInput 
                        reg={register({
                            required: "Name is Required", 
                            minLength:
                            {
                                value: 2, 
                                message: "Name is too short."
                            }
                        }
                        )}  
                        name="name" 
                        label="Name" 
                        type="text" 
                        errors={errors.name} 
                    />

                    <TextInput 
                        reg={register({
                            required: "Email is Required",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Please enter a valid Email"
                            }
                        })}  
                        name="email" 
                        label="Email" 
                        type="text" 
                        errors={errors.email} 
                    />

                    <TextInput 
                        reg={register()}  
                        name="phone" 
                        label="Phone" 
                        type="text" 
                        errors={errors.phone} 
                    />

                    <TextareaInput 
                        reg={register({
                            required: "Enquiry is Required",
                            minLength:
                            {
                                value: 10, 
                                message: "Message is too short."
                            }
                        
                        })}  
                        name="enquiry" 
                        label="Enquiry"  
                        errors={errors.enquiry} 
                    />

                

                    <input type="submit" className="submit-btn btn" value="Submit" />
                </form>

                
            </div>
            </div>
        </main>
        {notifyMessage ? <FormMessage message={notifyMessage} /> : ''}
        </Layout>

    )
}
export const pageQuery = graphql`
     {
        headerImage: imageSharp(resolutions: {originalName: {eq: "header-crop_43A2025.jpg"}}) { 
            ...ResponsiveImage
        }
    }
`


export default ContactUs